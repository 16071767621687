import { PropType } from 'vue';
import { isObject, isString } from 'lodash';
import { declareExternalProps } from '../../types/typeShims';

export type OmniSearchItem<T = unknown> = T & {
  disabled?: boolean;
  iconUrl?: string;
  iconName?: string;
  iconTheme?: 'outlined' | 'filled';
};

export const OmniSearchDatasetRenderingProps = declareExternalProps({
  /**
   * User-friendly name of this dataset - used as a section header unless
   * hideHeader is set.
   */
  name: {
    type: String,
    default: undefined,
  },
  /**
   * If set, and the user-entered text is empty, this string is used
   * instead of the normal header text. Mostly used to label the set of
   * empty search items being shown.
   */
  emptySearchHeaderText: {
    type: String,
    default: undefined,
  },
  /**
   * If true, the header and empty search header are hidden in results.
   */
  hideHeader: {
    type: Boolean,
    default: false,
  },
  /**
   * Controls whether icons are displayed for the search results.
   */
  showAppSearchIcon: {
    type: Boolean,
    default: true,
  },
  /**
   * Optional class applied to suggestion elements.
   */
  suggestionClassName: {
    type: String,
    default: undefined,
  },
  /**
   * Class applied to suggestion elements when focused.
   */
  suggestionFocusedClassName: {
    type: String,
    default: undefined,
  },
  /**
   * Class applied to suggestion elements when disabled.
   */
  suggestionDisabledClassName: {
    type: String,
    default: undefined,
  },
});

export const OmniSearchLocalDatasetProps = declareExternalProps({
  /**
   * Identifier for the dataset. This is not used as an HTML id, so it only needs
   * to be unique within a particular instance of OmniSearch.
   */
  id: {
    type: String,
    required: true,
  },
  /**
   * Controls whether the dataset's items will appear in search results.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * List of items to search over.
   */
  items: {
    type: Array as PropType<OmniSearchItem[]>,
    default: () => [],
  },
  /**
   * List of items which will initially appear to the user if there are no search
   * terms entered. Most frequently used to provide a list of recent searches or
   * suggested searches.
   */
  emptySearchItems: {
    type: Array as PropType<OmniSearchItem[]>,
    default: () => [],
    validator(dir: OmniSearchItem[]) {
      return Array.isArray(dir);
    },
  },
  /**
   * Controls which key on each of the item in `items` is used to get a
   * unique identifier for the item. This is also the value that the dataset
   * searches over if `itemIndexKey` is not set.
   */
  itemIdentifier: {
    type: String,
    required: true,
  },
  /**
   * If set, the dataset will search for the user input in the value present
   * at this key, instead of searching over the display name.
   */
  itemDisplayNameKey: {
    type: String,
    required: true,
  },
  /**
   * Controls which of the properties on each of the item in `items` is
   * used to get the index key for the item.
   */
  itemIndexKey: {
    type: String,
    default: null,
  },
  /**
   * Controls whether the search results should be sorted before
   * displaying them to the user.
   * If set to true, the items will automatically be sorted by the value at
   * `itemDisplayNameKey`.
   * If set to a string, the items will be sorted by `item[sorted]`.
   * If set to false, the items will not be sorted, and will appear in the order
   * that they appear in the `items` list.
   *
   * Note that if allowCreate is true, exact matches are always shown at the beginning
   * of the results list regardless of the sorting behavior specified here.
   */
  sorted: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Controls the number of search results shown at a time.
   */
  shownSuggestionsCap: {
    type: Number,
    default: 5,
  },
  /**
   * This is to maintain rendering performance when datasets may be large. Unlike
   * `shownSuggestionsCap`, this also affects empty search items.
   * If this is gets in your way, you may responsibly override it using the prop.
   *
   * This is to increase rendering performance, so in cases with complicated
   * list item templates, you may want to lower this number so the interface feels quick.
   *
   * To remove this limitation entirely, set it to Infinity. Do so responsibly.
   */
  maxResults: {
    type: Number,
    default: 500,
  },
  /**
   * When set, an additional "create" item will be added to the available options.
   * When selected, the dataset emits a @create event instead of @select.
   * Control the rendering of the option using the #create-item slot.
   *
   * The create option is only shown when there is not an exact match to the search
   * text (ignoring case) already found in the results.
   *
   * Options are:
   * - true = always show the create option (unless there is an exact-matching result already shown)
   * - false = never show the create option
   * - 'when-empty' = only show the create option when there are no other results
   */
  allowCreate: {
    type: [Boolean, String] as PropType<boolean | 'when-empty'>,
    default: false,
  },
  /**
   * When true, a button is shown below the results when they're capped which allows the user to see all the results.
   */
  allowShowMore: {
    type: Boolean,
    default: false,
  },
  ...OmniSearchDatasetRenderingProps,
});

export const OmniSearchDatasetEvents = {
  /**
   * Emitted when a search result is selected.
   */
  select: (item: any) => isObject(item),
  /**
   * Emitted when the create option is chosen (enabled by the allow-create prop)
   */
  create: (searchText: string) => isString(searchText),
};

export const CREATE_ITEM_ID = Symbol('create-item');
