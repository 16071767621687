<template>
  <OmniSearchDatasetResults
    :id="id"
    :results="cappedResults"
    :are-results-capped="areResultsCapped"
    :item-identifier="itemIdentifier"
    :item-display-name-key="itemDisplayNameKey"
    v-bind="renderingProps"
    @select="(item) => $emit('select', item)"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </OmniSearchDatasetResults>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, PropType, ref, toRefs, unref, watch } from 'vue';
import { debounce, pick, keys, isEmpty } from 'lodash';

import { FULLY_COMPATIBLE } from '../../utils/compat';
import { useConsumeOmniSearchContext } from './useOmniSearchContext';
import OmniSearchDatasetResults from './OmniSearchDatasetResults.vue';
import {
  OmniSearchItem,
  OmniSearchDatasetRenderingProps,
  OmniSearchDatasetEvents,
} from './omniSearch.types';
import { useOmniSearchLimits } from './useOmniSearchLimits';

export default defineComponent({
  name: 'OmniSearchRemoteDataset',
  components: {
    OmniSearchDatasetResults,
  },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Identifier for the dataset. This is not used as an HTML id, so it only needs
     * to be unique within a particular instance of OmniSearch.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Controls whether the dataset's items will appear in search results.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * A function that executes an async search using the provided text and returns
     * a list of matching items. These items will be displayed in the exact order
     * they are returned in (subject to any caps on the number of items).
     */
    searchItems: {
      type: Function as PropType<(searchText: string) => MaybePromise<OmniSearchItem[]>>,
      required: true,
    },
    /**
     * List of items which will initially appear to the user if there are no search
     * terms entered. Most frequently used to provide a list of recent searches or
     * suggested searches.
     */
    emptySearchItems: {
      type: Array as PropType<OmniSearchItem[]>,
      default: () => [],
      validator(dir: OmniSearchItem[]) {
        return Array.isArray(dir);
      },
    },
    /**
     * Controls which key on each of the item in `items` is used to get a
     * unique identifier for the item. This is also the value that the dataset
     * searches over if `itemIndexKey` is not set.
     */
    itemIdentifier: {
      type: String,
      required: true,
    },
    /**
     * If set, the dataset will search for the user input in the value present
     * at this key, instead of searching over the display name.
     */
    itemDisplayNameKey: {
      type: String,
      required: true,
    },
    /**
     * Controls which of the properties on each of the item in `items` is
     * used to get the index key for the item.
     */
    itemIndexKey: {
      type: String,
      default: null,
    },
    /**
     * Controls the number of search results shown at a time.
     */
    shownSuggestionsCap: {
      type: Number,
      default: 5,
    },
    /**
     * This is to maintain rendering performance when datasets may be large. Unlike
     * `shownSuggestionsCap`, this also affects empty search items.
     * If this is gets in your way, you may responsibly override it using the prop.
     *
     * This is to increase rendering performance, so in cases with complicated
     * list item templates, you may want to lower this number so the interface feels quick.
     *
     * To remove this limitation entirely, set it to Infinity. Do so responsibly.
     */
    maxResults: {
      type: Number,
      default: 500,
    },
    ...OmniSearchDatasetRenderingProps,
  },
  emits: {
    ...OmniSearchDatasetEvents,
  },
  setup(props) {
    const context = useConsumeOmniSearchContext();

    // We want the first empty input event to trigger an update, so we start at null instead of ''
    const searchText = ref<string | null>(null);
    context.on('input', (newSearchText) => {
      searchText.value = newSearchText;
    });

    const debouncedFetch = debounce(
      async (search: string, cb: (items: OmniSearchItem[]) => void) => {
        // this is using a callback as this function will only return some of the time
        cb(await props.searchItems(search));
      },
      200,
      { leading: false, trailing: true },
    );

    const rawResults = ref<OmniSearchItem[]>([]);
    watch(searchText, (newSearchText, oldSearchText) => {
      if (newSearchText === null || props.disabled) {
        return; // Skip the fetch, we're not going to need any data
      }

      // Some cases where we want to clear the results while the user is waiting for the fetch:
      // - They've completely cleared their input
      // - They have changed what they're typing (some prefix of their existing search has changed)
      // If newSearchText is just oldSearchText + (some more stuff), then we don't want to clear the
      // search as it's disorienting when they're trying to refine an existing search and it flickers as
      // they type.
      if (isEmpty(newSearchText)) {
        rawResults.value = [];
        return;
      }
      if (oldSearchText && !newSearchText.startsWith(oldSearchText)) {
        rawResults.value = [];
      }

      context.emit('loading:start', { id: props.id });
      debouncedFetch(newSearchText, (results) => {
        if (newSearchText !== unref(searchText)) {
          return; // Stale results, we'll wait for the next call
        }
        rawResults.value = results;
        context.emit('loading:end', { id: props.id });
      });
    });

    onUnmounted(() => {
      debouncedFetch?.cancel?.();
    });

    const { disabled, shownSuggestionsCap, maxResults, emptySearchItems } = toRefs(props);
    const { cappedResults, areResultsCapped } = useOmniSearchLimits(rawResults, {
      disabled,
      searchText,
      emptySearchItems,
      shownSuggestionsCap,
      maxResults,
    });

    const renderingProps = computed(() => pick(props, keys(OmniSearchDatasetRenderingProps)));

    return {
      renderingProps,
      cappedResults,
      areResultsCapped,
    };
  },
});
</script>
