<template>
  <div v-if="results.length" class="omni-search-dataset" :data-dataset="id">
    <div
      v-if="!hideHeader"
      :id="uid"
      class="omni-search-dataset-header d-flex px-3 py-2 text-uppercase sticky-top bg-white"
    >
      {{ isEmptySearch && emptySearchHeaderText ? emptySearchHeaderText : name }}
    </div>
    <div role="presentation" :aria-labelledby="uid">
      <div
        v-for="(item, itemIndex) in results"
        :key="String(item[itemIdentifier])"
        class="omni-search-suggestion py-2"
        :class="[
          {
            'omni-search-suggestion-focused': focusedIndex === itemIndex,
            'omni-search-suggestion-disabled': item.disabled,
          },
          suggestionClassName,
          focusedIndex === itemIndex && suggestionFocusedClassName
            ? suggestionFocusedClassName
            : false,
          item.disabled && suggestionDisabledClassName ? suggestionDisabledClassName : false,
        ]"
        :data-suggestion="String(item[itemIdentifier])"
        data-testid="search-suggestion"
        @mouseover="() => focusItem(itemIndex)"
        @click.prevent.stop="() => selectItem(itemIndex)"
      >
        <div class="text-nowrap overflow-hidden text-truncate d-flex align-items-center px-3">
          <slot
            v-if="item[itemIdentifier] === CREATE_ITEM_ID"
            name="create-item"
            :search-text="searchText"
          >
            Create "{{ searchText }}"
          </slot>
          <slot v-else :item="item" :search="searchText ?? ''" :disabled="item.disabled">
            <template v-if="showAppSearchIcon">
              <BaseIcon
                v-if="item.iconName"
                :name="item.iconName"
                :theme="item.iconTheme"
                :size="20"
                class="mr-2"
              />
              <AppIcon
                v-else
                width="20"
                height="20"
                class="mr-2"
                :icon-src="item.iconUrl"
                :inactive="item.disabled"
              />
            </template>

            <OmniSearchTextMatch
              class="omni-search-name pr-2 d-inline-block text-truncate overflow-hidden"
              :search="searchText ?? ''"
              :result="item[itemDisplayNameKey]"
              :disabled="item.disabled"
            />
          </slot>
        </div>
      </div>
    </div>
    <template v-if="areResultsCapped">
      <BaseButton
        v-if="allowShowMore"
        theme="link"
        data-testid="show-more-results"
        class="w-100 text-left my-1 px-3"
        @mousedown.prevent="$emit('show-more')"
      >
        Show {{ numCappedResults }} more
      </BaseButton>
      <div v-else class="my-1 px-3 text-caption text-gray" data-testid="results-capped-notice">
        There are more results than can be shown, continue searching to refine the list
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.omni-search-dataset:not(:last-of-type) {
  border-bottom: $border-width solid $border-color;
}

.omni-search-dataset:first-child {
  .omni-search-dataset-header {
    border-top: 0;
  }
}
.omni-search-dataset:not(:first-child) {
  margin-top: $spacer * 0.25;
}

.omni-search-dataset {
  .omni-search-dataset-header {
    color: $gray;
    font-weight: 700;
    font-size: $caption-font-size;
    line-height: 1rem;
  }
}

.omni-search-suggestion {
  cursor: pointer;

  &.omni-search-suggestion-focused {
    background-color: palette-color('blue', 'light');
  }

  &.omni-search-suggestion-disabled {
    color: $gray-300;
    cursor: not-allowed;

    .omni-search-icon {
      filter: saturate(0.6) opacity(0.7);
    }
  }
}
</style>

<script lang="ts">
import { PropType, computed, defineComponent, ref, toRef, unref, watch } from 'vue';
import { v4 as uuid } from 'uuid';
import { filter } from 'lodash';

import AppIcon from '../AppIcon.vue';
import BaseIcon from '../BaseIcon.vue';
import BaseButton from '../BaseButton.vue';
import { FULLY_COMPATIBLE } from '../../utils/compat';
import OmniSearchTextMatch from './OmniSearchTextMatch.vue';
import { useConsumeOmniSearchContext } from './useOmniSearchContext';
import {
  TargetedDatasetPayload,
  DatasetItemFocusPayload,
  DatasetFocusMovePayload,
  OMNI_DATASET_NAVIGATION_DIRECTION,
} from './useOmniSearch';
import {
  OmniSearchItem,
  OmniSearchDatasetRenderingProps,
  OmniSearchDatasetEvents,
  CREATE_ITEM_ID,
} from './omniSearch.types';

/**
 * Shared base component for OmniSearchDataset components. Responsible for:
 *  - Rendering results and other decoration (header/footer)
 *  - Rendering focus states and handling focus transitions
 *  - Dispatching select events on click and resolving keyboard select events to the appropriate item
 *  - Emitting match events when results change
 */
export default defineComponent({
  name: 'OmniSearchDatasetResults',
  components: {
    OmniSearchTextMatch,
    BaseIcon,
    AppIcon,
    BaseButton,
  },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * The ID of the dataset being rendered by this component.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The exact list of results to render.
     */
    results: {
      type: Array as PropType<OmniSearchItem[]>,
      required: true,
    },
    /**
     * Controls which key on each of the item in `items` is used to get a
     * unique identifier for the item. This is also the value that the dataset
     * searches over if `itemIndexKey` is not set.
     */
    itemIdentifier: {
      type: String,
      required: true,
    },
    /**
     * If set, the dataset will search for the user input in the value present
     * at this key, instead of searching over the display name.
     */
    itemDisplayNameKey: {
      type: String,
      required: true,
    },
    /**
     * If true, shows a warning about additional results that are not being shown
     */
    areResultsCapped: {
      type: Boolean,
      default: false,
    },
    allowShowMore: {
      type: Boolean,
      default: false,
    },
    /**
     * The number of results not being shown, shown in the show more dialog. Only used when allowShowMore=true
     */
    numCappedResults: {
      type: Number,
      default: undefined,
    },
    ...OmniSearchDatasetRenderingProps,
  },
  emits: {
    ...OmniSearchDatasetEvents,
    'show-more': () => true,
  },
  setup: (props, { emit }) => {
    const uid = uuid();

    const context = useConsumeOmniSearchContext();

    const searchText = ref<string | null>(null);
    context.on('input', (newSearchText) => {
      searchText.value = newSearchText;
    });

    const focusedIndex = ref<number | null>(null);

    const isTargetingSelf = (
      payload?: TargetedDatasetPayload,
    ): payload is TargetedDatasetPayload => {
      if (payload === undefined) {
        throw new Error('Payload is undefined.');
      }
      return payload.targetDatasetId === props.id;
    };

    const emitPrivateEvent = (eventName: string, ...payload: any[]) => {
      context.emit(eventName, { id: props.id, payload });
    };

    context.on<DatasetItemFocusPayload>(`focus:dataset:item`, (payload) => {
      if (!isTargetingSelf(payload)) {
        return;
      }
      focusedIndex.value = payload.itemIndex;
    });

    // NOTE(@zkirby): This is needed as moving between items within in a dataset is handled
    // by the dataset itself. This is useful for supporting things like submenus (see OmniSearchSubmenuDataset)
    // so the dataset can navigate between submenu items using the input events.
    // In the case of the default dataset as seen here, we need only to move between search items
    context.on<DatasetFocusMovePayload>('focus:dataset:move', (payload) => {
      const eventMap = {
        [OMNI_DATASET_NAVIGATION_DIRECTION.NEXT]: 'focus:next',
        [OMNI_DATASET_NAVIGATION_DIRECTION.PREVIOUS]: 'focus:previous',
      } as const;

      if (isTargetingSelf(payload) && payload.direction in eventMap) {
        emitPrivateEvent(eventMap[payload.direction]);
      }
    });

    const selectItem = (index: number) => {
      const item = props.results[index];
      if (item && !item.disabled) {
        emitPrivateEvent('select');
        if (item[props.itemIdentifier] === CREATE_ITEM_ID) {
          emit('create', unref(searchText) ?? '');
        } else {
          emit('select', item);
        }
      }
    };

    context.on('select', () => {
      const currentFocus = unref(focusedIndex);
      if (currentFocus !== null) {
        selectItem(currentFocus);
      }
    });

    const focusItem = (index: number) => {
      const item = props.results[index];
      if (!item.disabled) {
        emitPrivateEvent('focus:item', index);
      }
    };

    const isEmptySearch = computed(() => unref(searchText)?.length === 0);

    // Make sure the overall OmniSearch knows whether we have anything to show, so it can decide
    // whether the menu should be open.
    watch(
      toRef(props, 'results'),
      (newResults) => {
        const enabledResults = filter(newResults, (result) => !result.disabled);
        // We also want to distinguish between empty search (null) and no results (empty array)
        if (enabledResults.length === 0 && unref(isEmptySearch)) {
          emitPrivateEvent('match', null);
        } else {
          emitPrivateEvent('match', enabledResults);
        }
      },
      { immediate: true },
    );

    return {
      CREATE_ITEM_ID,
      uid,
      focusedIndex,
      focusItem,
      selectItem,
      searchText,
      isEmptySearch,
    };
  },
});
</script>
