export interface Compliance {
  name: string;
  status: string;
}

interface Vendor {
  name: string;
  iconUrl: string;
  id: string;
}

interface Owner {
  email: string;
  ownerType: string;
  name: string;
  applicationId: string;
  isDefault: boolean;
  isInternal: boolean;
  isActiveEmployee: boolean;
}

interface OwnerType {
  label: string;
  value: string;
  autoProvision: boolean;
  defaultRole: string | null;
}

interface Capability {
  ct: string;
  id: string;
  name: string;
  type: 'appCapability';
  recommended: boolean;
}

export interface ListedApp {
  id: string;
  name: string;
  iconUrl: string;
  compliance: Compliance[];
  appCapabilities: {
    visible: Capability[];
    hidden: Capability[];
  };
  description: string;
  website: string;
  vendor: Vendor;
  category: string;
  isFeatured: boolean;
  organization?: number;
  team?: number;
  ssoSupport: {
    saml: string;
  };
  appOwners: {
    success: boolean;
    owners: Owner[];
    ownerTypes: OwnerType[];
  };
  activity?: {
    activeCount: number;
    inactiveCount: number;
    provisionedCount: number;
    activitySource: string;
    provisionedSpend?: {
      currencyCode: string;
      amount: number;
    };
  };
}

export enum REQUESTS_ROUTE_NAMES {
  APP_REQUEST = 'app-request',
  PROCURE_NEW_APP = 'procure-new-app',
  MY_REQUESTS = 'my-requests',
  ALL_REQUESTS = 'all-requests',
  APPROVAL_REQUESTS = 'approvals',
  SUBMIT_REQUEST = 'submit-request',
  PROCURE_ACS_APP = 'procure-acs-app',
}

export enum REQUEST_TYPE_ID {
  GENERAL_INTAKE = 'general_intake',
  SOFTWARE_INTAKE = 'software_intake',
}

export enum STATUS_TYPE {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const STATUS_TO_LABEL_MAP = {
  [STATUS_TYPE.COMPLETED]: 'Completed',
  [STATUS_TYPE.IN_PROGRESS]: 'In progress',
} as const;

const REQUEST_TABLE_COLUMNS = {
  REQUEST_DETAILS: {
    name: 'Request details',
    sortColumn: 'applicationDetails.displayName',
  },
  REQUESTED_BY: {
    name: 'Requested by',
    sortColumn: 'requestedBy.first_name',
  },
  REQUESTED_ON: {
    name: 'Requested on',
    sortColumn: 'createdAt',
  },
  CURRENT_ASSIGNEES: {
    name: 'Current assignees',
    sortColumn: 'currentAssignee.first_name',
  },
  LAST_ACTIVITY: {
    name: 'Last activity on',
    sortColumn: 'lastActivity',
  },
  STATUS: {
    name: 'Status',
    sortColumn: 'status',
  },
};
export const ALL_REQUEST_TABLE_COLUMNS = [
  REQUEST_TABLE_COLUMNS.REQUEST_DETAILS,
  REQUEST_TABLE_COLUMNS.REQUESTED_BY,
  REQUEST_TABLE_COLUMNS.REQUESTED_ON,
  REQUEST_TABLE_COLUMNS.CURRENT_ASSIGNEES,
  REQUEST_TABLE_COLUMNS.LAST_ACTIVITY,
  REQUEST_TABLE_COLUMNS.STATUS,
];

export const APPROVAL_REQUEST_TABLE_COLUMNS = [
  REQUEST_TABLE_COLUMNS.REQUEST_DETAILS,
  REQUEST_TABLE_COLUMNS.REQUESTED_BY,
  REQUEST_TABLE_COLUMNS.REQUESTED_ON,
  REQUEST_TABLE_COLUMNS.LAST_ACTIVITY,
  REQUEST_TABLE_COLUMNS.STATUS,
];

export const MY_REQUEST_TABLE_COLUMNS = [
  REQUEST_TABLE_COLUMNS.REQUEST_DETAILS,
  REQUEST_TABLE_COLUMNS.REQUESTED_ON,
  REQUEST_TABLE_COLUMNS.LAST_ACTIVITY,
  REQUEST_TABLE_COLUMNS.CURRENT_ASSIGNEES,
  REQUEST_TABLE_COLUMNS.STATUS,
];

export enum RequestType {
  ACCESS_REQUEST = 'access_request',
  SOFTWARE_INTAKE = 'software_intake',
  INTAKE = 'general_intake',
}

export enum SLACK_TYPES {
  USER = 'user',
  CHANNEL = 'channel',
  PRIVATE_CHANNEL = 'private_channel',
}

export interface SlackUser {
  name: string;
  type: SLACK_TYPES.USER;
  slackId: string;
}

export interface SlackChannel {
  name: string;
  type: SLACK_TYPES.CHANNEL;
  slackId: string;
}

export interface SlackPrivateChannel {
  name: string;
  type: SLACK_TYPES.PRIVATE_CHANNEL;
  slackId: string;
}

export interface ApiSlack {
  name: string;
  email?: string;
  iconUrl?: string;
  type: string;
  private?: boolean;
  slackId: string;
}

export interface ApiUser {
  name: string;
  email: string;
}

export type SlackApprover = SlackUser | SlackChannel | SlackPrivateChannel;

export const SLACK_ICON_MAP: Record<SLACK_TYPES, string> = {
  [SLACK_TYPES.USER]: 'user-large',
  [SLACK_TYPES.CHANNEL]: 'hashtag',
  [SLACK_TYPES.PRIVATE_CHANNEL]: 'lock',
};
