<template>
  <div
    class="omni-search-container position-relative d-flex align-items-center"
    :class="showSuggestionsMenu && 'expanded'"
  >
    <slot
      name="input"
      :value="userEnteredText"
      :on-input="onInput"
      :on-focus="onInputFocus"
      :on-reset="onReset"
      :on-blur="onInputBlur"
      :on-keydown="onKeydown"
    >
      <BaseSearchInput
        :id="inputId"
        ref="inputEl"
        :name="inputName"
        class="input-wrapper w-100 position-relative"
        :class="[
          showSuggestionsMenu && 'expanded',
          `variant-${menuVariant}`,
          `direction-${dropDirection}`,
        ]"
        :input-size="inputSize"
        :model-value="userEnteredText"
        aria-autocomplete="list"
        role="combobox"
        :hide-clear="hideClear"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :disabled="disabled"
        :icon="icon"
        @input="onInput"
        @focus="onInputFocus"
        @reset="onReset"
        @blur="onInputBlur"
        @keydown="onKeydown"
      >
        <template #icon>
          <BaseSpinner v-if="showLoadingState" :size="16" />
          <slot v-else name="icon" />
        </template>

        <template #rightIcon>
          <slot name="rightIcon" />
        </template>
      </BaseSearchInput>
    </slot>

    <div
      v-show="showSuggestionsMenu"
      class="omni-search-menu bg-white"
      :class="[`direction-${dropDirection}`, `variant-${menuVariant}`, `size-${inputSize}`]"
      role="listbox"
      :aria-expanded="showSuggestionsMenu ? true : undefined"
      @mouseenter="mouseOverSuggestionMenu = true"
      @mouseleave="mouseOverSuggestionMenu = false"
    >
      <div
        class="pb-2 overflow-auto"
        :style="
          maxMenuHeight
            ? {
                maxHeight: typeof maxMenuHeight === 'number' ? `${maxMenuHeight}px` : maxMenuHeight,
              }
            : undefined
        "
      >
        <slot />
        <p
          v-if="!foundResults && !hideNoMatchesFoundText"
          class="omni-search-no-matches mb-0 px-4 py-2"
        >
          <slot name="no-matches">
            <span class="font-italic text-detail font-italic">No matches found</span>
          </slot>
        </p>

        <div v-if="$slots.footer" class="px-3 py-2">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.omni-search-container {
  .input-wrapper {
    z-index: 2;

    // make the focus border on the input container appear to merge with the
    // dropdown menu when using the merged variant
    &.expanded.variant-merged {
      z-index: $zindex-dropdown + 1;

      &.direction-down {
        transition: border-bottom 0s;
        border-bottom-color: $white;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.direction-up {
        transition: border-top 0s;
        border-top-color: $white;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      & ~ .omni-search-menu.variant-merged {
        border: 1px solid $blue;
      }
    }
  }

  .omni-search-menu {
    position: absolute;
    z-index: $zindex-dropdown;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    width: 100%;
    padding-top: 0.5rem;
    // This was designed with the Global Header search bar in mind, and will likely let other search experiences go off screen before they scroll
    // Pending a better solution
    max-height: 90vh;
    overflow-y: auto;

    .omni-search-no-matches:not(:first-child) {
      display: none;
    }

    $input-size-to-menu-offset: (
      sm: $input-height-sm,
      md: $input-height,
      lg: $input-height-lg,
    );
    $menu-directions: (
      up: 'bottom',
      down: 'top',
    );

    // we need to vary the offset distance for the menu based on the
    // input size, the direction/anchor based on whether the menu
    // goes up or down (anchor menu at bottom of input for drop-up,
    // vice-versa for drop-down), and whether we use margin or
    // padding based on whether or not the menu should appear merged
    // with the input (padding) or not (margin).  the loops
    // here set up the variables we need to construct the one line
    // we need for each of these 12 cases.
    @each $menu-direction, $position-anchor in $menu-directions {
      &.direction-#{$menu-direction} {
        &.variant-separate {
          @each $input-size, $spacing in $input-size-to-menu-offset {
            &.size-#{$input-size} {
              #{$position-anchor}: 100%;
            }
          }
        }

        &.variant-merged {
          // helps merged menu not interfere with the input focus border
          #{$position-anchor}: $border-width;

          @each $input-size, $spacing in $input-size-to-menu-offset {
            &.size-#{$input-size} {
              padding-#{$position-anchor}: calc(#{$spacing} + #{$spacer * 0.25});
            }
          }
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs, unref, watch } from 'vue';
import { some, isString, stubTrue as noPayload, isNil, isNumber, isObject, isEmpty } from 'lodash';

import BaseSearchInput from '../BaseSearchInput.vue';

import { FULLY_COMPATIBLE } from '../../utils/compat';
import BaseSpinner from '../BaseSpinner.vue';
import useOmniSearch, {
  DatasetFocusMovePayload,
  DatasetItemFocusPayload,
  OMNI_DATASET_NAVIGATION_DIRECTION,
} from './useOmniSearch';

export default defineComponent({
  name: 'OmniSearch',
  compatConfig: FULLY_COMPATIBLE,
  components: {
    BaseSpinner,
    BaseSearchInput,
  },
  props: {
    /**
     * Search input text placeholder.
     */
    placeholder: {
      type: String,
      default: 'Search',
    },
    /**
     * Controls whether or not the input and controls of the OmniSearch can be used.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the direction the search recommendations are displayed in.
     */
    dropDirection: {
      type: String as PropType<'down' | 'up'>,
      default: 'down',
      validator(dir: unknown): dir is 'down' | 'up' {
        return isString(dir) && ['down', 'up'].includes(dir);
      },
    },
    /**
     * Controls whether or not the search results menu is visually connected to the search input.
     */
    menuVariant: {
      type: String as PropType<'separate' | 'merged'>,
      default: 'separate',
      validator(dir: unknown): dir is 'separate' | 'merged' {
        return isString(dir) && ['separate', 'merged'].includes(dir);
      },
    },
    /**
     * Controls the size of the search input control.
     */
    inputSize: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
      validator(value: unknown): value is 'sm' | 'md' | 'lg' {
        return isString(value) && ['sm', 'md', 'lg'].includes(value);
      },
    },
    /**
     * Controls whether or not the input is automatically focused when the component mounts.
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls whether or not the clear button is displayed when there is some input in the
     * search box.
     */
    hideClear: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls whether or not the search input renders a dropdown menu with results.
     *
     * This primarily exists to support using this component to power "live filter" search bars,
     * but in that case you lose out on many of the features and power of OmniSearch, and a
     * simpler implementation may be more worthwhile.
     *
     * @deprecated Consider using OmniSearchList instead, which implements a live search menu on top of OmniSearch.
     */
    noAutocomplete: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the initial text in the search input.
     */
    initialSearchText: {
      type: String,
      default: '',
    },
    /**
     * Controls what text remains in the search bar after the user selects a result.
     * By default, the search input will be cleared after a result is selected.
     * Set this prop to false to disable this behavior, or set it to another string
     * to reset the input to that given string after a selection occurs.
     */
    searchTextAfterSelection: {
      type: [String, Boolean] as PropType<string | false>,
      validator(value) {
        return isString(value) || value === false;
      },
      default: '',
    },
    /**
     * Controls whether or not the "No matches found" text is displayed when
     * there are no matching search results.
     */
    hideNoMatchesFoundText: {
      type: Boolean,
      default: false,
    },
    /**
     * Name of the icon to be rendered to the left of the search input. Allows you
     * to override the default icon. Use false to disable the icon entirely.
     */
    icon: {
      type: [String, Boolean] as PropType<string | false | undefined>,
      default: undefined,
    },
    /**
     * Controls whether or not the search input remains focused after a result is
     * selected.
     */
    maintainFocusOnSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the maximum height of the search results menu in pixels (if a number) or as a
     * raw CSS value (if a string) with units provided.
     */
    maxMenuHeight: {
      type: [Number, String],
      default: undefined,
    },
    /**
     * id attribute to put on the input element
     * Has no effect if input slot is overridden
     */
    inputId: {
      type: String,
      default: undefined,
    },
    /**
     * name attribute to put on the input element
     * Has no effect if input slot is overridden
     */
    inputName: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    /**
     * Emitted when the search input has lost focus.
     */
    blur: noPayload,
    /**
     * Emitted when the search input has a value.
     */
    input: isString,
    /**
     * Emitted when the clear button is clicked.
     */
    reset: noPayload,
    /**
     * Emitted when an individudal entry is focused.
     */
    'focus:dataset:item': (payload): payload is DatasetItemFocusPayload => {
      return (
        isObject(payload) &&
        isString(payload['targetDatasetId']) &&
        some([isNil(payload['itemIndex']), isNumber(payload['itemIndex'])])
      );
    },
    /**
     * Emitted when focus moves.
     */
    'focus:dataset:move': (payload): payload is DatasetFocusMovePayload => {
      return (
        isObject(payload) &&
        isString(payload['targetDatasetId']) &&
        Object.values(OMNI_DATASET_NAVIGATION_DIRECTION).includes(payload['direction'])
      );
    },
  },
  setup(props, { emit, expose }) {
    const { noAutocomplete, searchTextAfterSelection, disabled, hideNoMatchesFoundText } =
      toRefs(props);
    const mouseOverSuggestionMenu = ref(false);
    const isFocused = ref(false);
    const inputEl = ref();

    const {
      userEnteredText,
      foundResults,
      moveFocusNext,
      moveFocusPrevious,
      moveFocusIn,
      moveFocusOut,
      emitToChildren,
      onParentMessage,
      isLoading,
    } = useOmniSearch({
      initialSearchText: props.initialSearchText,
      searchTextAfterSelection,
    });

    function focus() {
      unref(inputEl)?.focus();
    }

    /**
     * This function is part of the external api of the component
     */
    expose({ focus });

    function onInputFocus() {
      isFocused.value = true;
      emitToChildren('focus');
      emitToChildren('input', userEnteredText.value);
    }

    function onInputBlur() {
      emit('blur');
      isFocused.value = false;
    }

    function onEnterClick() {
      if (!unref(noAutocomplete)) {
        emitToChildren('select');
      }
    }

    onParentMessage('select', () => {
      if (!props.maintainFocusOnSelect) {
        unref(inputEl)?.blur();
      }
    });

    watch(disabled, (isDisabled) => {
      if (isDisabled) {
        mouseOverSuggestionMenu.value = false;
        isFocused.value = false;
      }
    });

    const showSuggestionsMenu = computed(() => {
      const hasUserFocus = unref(isFocused) || unref(mouseOverSuggestionMenu);
      const hasContent =
        unref(foundResults) ||
        (unref(userEnteredText)?.length > 0 && !unref(hideNoMatchesFoundText));

      return hasUserFocus && hasContent && !unref(noAutocomplete);
    });

    watch(showSuggestionsMenu, (isOpen) => {
      if (!isOpen) {
        emitToChildren('closed');
      }
    });

    function onInput(value) {
      emit('input', value);
      userEnteredText.value = value;
    }

    function onReset() {
      emit('reset');
    }

    function onKeydown(event: KeyboardEvent) {
      if (event.code === 'ArrowUp') {
        moveFocusPrevious(event);
      } else if (event.code === 'ArrowDown') {
        moveFocusNext(event);
      } else if (event.code === 'ArrowRight') {
        moveFocusIn(event);
      } else if (event.code === 'ArrowLeft') {
        moveFocusOut(event);
      } else if (event.code === 'Enter') {
        onEnterClick();
      }
    }

    const showLoadingState = computed(() => {
      return !isEmpty(unref(userEnteredText)) && unref(isLoading);
    });

    return {
      showSuggestionsMenu,
      mouseOverSuggestionMenu,
      userEnteredText,
      isFocused,
      foundResults,
      onKeydown,
      onInput,
      onInputFocus,
      onInputBlur,
      onEnterClick,
      onReset,
      emitToChildren,
      showLoadingState,
      inputEl,
    };
  },
});
</script>
