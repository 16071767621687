import { createInput } from '@formkit/vue';
import { markRaw } from 'vue';
import { fromPairs } from 'lodash';
import { LOOKUP_FIELD_PASS_THROUGH_PROPS } from '../forms.types';
import LookupField from './LookupField.vue';

export type LookupResult<T = Record<string, any>> =
  | {
      type: 'create';
      text: string;
    }
  | {
      type: 'select';
      selection: T;
    };

export default createInput(
  () => (/* extensions */) => ({
    $cmp: markRaw(LookupField),
    bind: '$attrs',
    props: {
      // Bind from node.[prop] to LookupField component prop
      ...fromPairs(LOOKUP_FIELD_PASS_THROUGH_PROPS.map((prop) => [prop, `$${prop}`])),
      disabled: '$disabled',
      value: '$_value',
      onInput: '$node.input',
      onBlur: '$handlers.blur',
      id: '$id',
      name: '$node.name',
    },
  }),
  {
    type: 'input',
    family: 'text',
    props: LOOKUP_FIELD_PASS_THROUGH_PROPS,
  },
);
