import { isFunction } from 'lodash';

export enum FormElementRuleEffect {
  HideElement = 'HIDE',
  ShowElement = 'SHOW',
}

// NOTE(@alexv): encodes the expected field value in TValue, for later
// extraction. Really I wish this could extend little-s string but that isn't
// allowed, and using `type` instead of `interface` also does not seem to work.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TargetField<TValue> extends String {}

export type ExtractFieldValue<TField extends TargetField<any>> =
  TField extends TargetField<infer TValue> ? TValue : never;

interface BaseFieldCondition {
  key: TargetField<unknown>;
  args: any;
}

export interface BooleanCondition extends BaseFieldCondition {
  key: TargetField<boolean>;
  predicate: 'isTrue' | 'isFalse';
  args: undefined;
}

export interface AbsoluteDateCondition extends BaseFieldCondition {
  key: TargetField<string>;
  predicate: 'd_before' | 'd_after' | 'd_onOrBefore' | 'd_onOrAfter';
  args: string;
}

// Not implemented. Unclear what date is the reference for "last" or "next"
export interface RelativeDateCondition extends BaseFieldCondition {
  key: TargetField<string>;
  predicate: 'd_inLast' | 'd_notInLast' | 'd_inNext' | 'd_notInNext';
  args: number;
}

interface OneOfCondition extends BaseFieldCondition {
  key: TargetField<string>;
  predicate: 'oneOf' | 'notOneOf';
  args: string[];
}

interface ContainsCondition extends BaseFieldCondition {
  key: TargetField<string>;
  predicate: 'contains' | 'notContains';
  args: string;
}

export type StringCondition = ContainsCondition | OneOfCondition;

export interface ExistenceCondition extends BaseFieldCondition {
  predicate: 'isEmpty' | 'isNotEmpty';
  args: undefined;
}

interface BinaryNumericCondition extends BaseFieldCondition {
  key: TargetField<number>;
  predicate: 'eq' | 'gt' | 'gte' | 'lt' | 'lte';
  args: number;
}

interface BetweenCondition extends BaseFieldCondition {
  key: TargetField<number>;
  predicate: 'between';
  args: { u: number; l: number };
}

export type NumericCondition = BinaryNumericCondition | BetweenCondition;

export type RadioCondition = OneOfCondition;

interface IncludesCondition extends BaseFieldCondition {
  key: TargetField<string[]>;
  predicate: 'includes' | 'notIncludes';
  args: string[];
}

// Not yet implemented
interface SameSetCondition extends BaseFieldCondition {
  key: TargetField<string[]>;
  predicate: 'sameSet' | 'notSameSet';
  args: string[];
}

export type CheckboxCondition = IncludesCondition | SameSetCondition;

export type DateCondition = AbsoluteDateCondition /*| RelativeDateCondition*/;

export type FieldCondition =
  | BooleanCondition
  | DateCondition
  | ExistenceCondition
  | RadioCondition
  | CheckboxCondition
  | StringCondition
  | NumericCondition;

export interface LogicalCondition {
  op: 'and' | 'or';
  // eslint-disable-next-line no-use-before-define
  children: ConditionExpression[];
}

export type ConditionExpression = LogicalCondition | FieldCondition;

export interface FormElementRule {
  effect: FormElementRuleEffect;
  condition: {
    root: ConditionExpression;
  };
}

export enum FormItemType {
  Element = 'ELEMENT',
  Input = 'INPUT',
}

export enum FormElementType {
  Header = 'FORM_HEADER',
}

export interface FormElement {
  id: string;
  type: FormItemType.Element;
  elementType: FormElementType;
  text: string;
}

export enum FormInputType {
  Choice = 'CHOICE',
  Application = 'APPLICATION',
  ShortText = 'SHORT_TEXT',
  LongText = 'LONG_TEXT',
  Vendor = 'VENDOR',
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Date = 'DATE',
  FileUpload = 'FILE_UPLOAD',
  Lookup = 'LOOKUP',
}

export interface BaseInput {
  id: string;
  type: FormItemType.Input;
  inputType: FormInputType;
  required: boolean;
  label: string;
  helpText?: string;
  rule?: FormElementRule;
}

export enum ChoiceInputType {
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
}

export type ChoiceOption = { value: string; label?: string };

export interface RadioInput extends BaseInput {
  inputType: FormInputType.Choice;
  choiceType: ChoiceInputType.Radio;
  options: ChoiceOption[];
}

export interface CheckboxInput extends BaseInput {
  inputType: FormInputType.Choice;
  choiceType: ChoiceInputType.Checkbox;
  minItems?: number;
  maxItems?: number;
  options: ChoiceOption[];
}

export type ChoiceInput = RadioInput | CheckboxInput;

export interface ApplicationInput extends BaseInput {
  inputType: FormInputType.Application;
  placeholder?: string;
}

export interface VendorInput extends BaseInput {
  inputType: FormInputType.Vendor;
  placeholder?: string;
}

export interface FileUploadInput extends BaseInput {
  inputType: FormInputType.FileUpload;
  multiple?: boolean;
  accept?: string;
}

export interface TextInput extends BaseInput {
  inputType: FormInputType.LongText | FormInputType.ShortText;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
}

export interface NumberInput extends BaseInput {
  inputType: FormInputType.Number;
  placeholder?: string;
  minimum?: number;
  maximum?: number;
}

export interface DateInput extends BaseInput {
  inputType: FormInputType.Date;
}

export interface CurrencyInput extends BaseInput {
  inputType: FormInputType.Currency;
  placeholder?: string;
  currencyCode: string;
  minimum?: number;
  maximum?: number;
}

/**
 * @see /services/workflow/app/forms/constants.js
 */
export enum LookupScope {
  CustomerVendors = 'customerVendors',
}
export enum LookupSearchType {
  Local = 'local', // Fetch the data once, search over it locally (/forms/lookup-list)
  Remote = 'remote', // Remote search each time the user has a query (/forms/lookup)
}

export interface LookupInput extends BaseInput {
  inputType: FormInputType.Lookup;
  placeholder?: string;
  scope: LookupScope;
  searchType: LookupSearchType;
  itemIdKey: string;
  itemDisplayNameKey: string;
  allowCreate: boolean;
}

/**
 * Fields that go directly from the input definition to the props of the
 * LookupField component
 */
export const LOOKUP_FIELD_PASS_THROUGH_PROPS: (keyof LookupInput)[] = [
  'scope',
  'searchType',
  'itemIdKey',
  'itemDisplayNameKey',
  'allowCreate',
  'placeholder',
] satisfies readonly (keyof LookupInput)[];

export type FormInput =
  | ChoiceInput
  | ApplicationInput
  | VendorInput
  | CurrencyInput
  | TextInput
  | NumberInput
  | DateInput
  | FileUploadInput
  | LookupInput;

export interface FormDefinition {
  items: (FormElement | FormInput)[];
}

/**
 * Throws an error with the given message. Intended to be used
 * to prove exhaustiveness via type narrowing.
 * @param value The value which should be exhausted.
 * @param message The message to use in the error. Any instances of `{value}`
 * are replaced by the passed in value.
 */
export function exhaust(value: never, message: string | ((v: any) => string)): never {
  throw new Error(isFunction(message) ? message(value as any) : message.replace('{value}', value));
}
