import { MaybeRef, Ref, computed, nextTick, onMounted, onUnmounted, ref, unref, watch } from 'vue';
import { some, values } from 'lodash';
import mitt from 'mitt';
import { useProvideOmniSearchContext } from './useOmniSearchContext';

type UseOmniSearchArgs = {
  initialSearchText?: string;
  searchTextAfterSelection?: MaybeRef<string | false>;
};

export const OMNI_DATASET_NAVIGATION_DIRECTION = {
  NEXT: 'next',
  PREVIOUS: 'previous',
  IN: 'inner',
  OUT: 'outer',
} as const;
type OMNI_DATASET_NAVIGATION_DIRECTION =
  (typeof OMNI_DATASET_NAVIGATION_DIRECTION)[keyof typeof OMNI_DATASET_NAVIGATION_DIRECTION];

export type TargetedDatasetPayload = { targetDatasetId: string };

/**
 * Represents the payload of an event which indicates that focus has changed to a specific item
 * of a specific dataset
 */
export type DatasetItemFocusPayload = TargetedDatasetPayload & {
  itemIndex: number | null;
};

/**
 * Represents the payload of an event which indicates that focus should move one item in the
 * given direction of the given dataset
 */
export type DatasetFocusMovePayload = TargetedDatasetPayload & {
  direction: (typeof OMNI_DATASET_NAVIGATION_DIRECTION)[keyof typeof OMNI_DATASET_NAVIGATION_DIRECTION];
};

type FocusedItem = {
  datasetId: string;
  itemIndex: number;
} | null;

export default function useOmniSearch({
  initialSearchText = '',
  searchTextAfterSelection,
}: UseOmniSearchArgs) {
  const userEnteredText = ref<string>(initialSearchText);
  const focusedItem = ref<FocusedItem>(null);
  const numMatchesMapRef = ref<Record<string, number>>({});
  const foundResults = computed(() => {
    return some(unref(numMatchesMapRef), (v) => v > 0);
  });
  const datasets = computed(() => Object.keys(unref(numMatchesMapRef)));
  const numDatasetsRef = computed(() => {
    return unref(datasets).length;
  });

  // Because submenus are handled by the submenu component, OmniSearch does not know about them.
  // However, in order to know whether to suppress left/right arrow events (as they're not text
  // selection movement but menu navigation) OmniSearch is concerned with whether a given dataset
  // has a submenu. This map tracks that.
  //
  // A better solution would maintain `numMatchesMapRef` as a more complex datastructure that maps
  // whether individual search results have a submenu, so then we can only override left/right arrow
  // keypresses when the exact focused item has a submenu. But this is a step in the right direction.
  const datasetSubmenuPresenceMap = ref<Record<string, boolean>>({});

  // NOTE: parentEmitter is used by the parent to emit messages to its children.
  // childEmitter is used by a child to emit messages to the parent.
  // we need two because otherwise datasets could listen to each other - omnisearch is built
  // on having datasets communicate only via omnisearch (not peer-to-peer)
  const parentEmitter = mitt();
  const emitToChildren = parentEmitter.emit;
  const childEmitter = mitt();

  onUnmounted(() => {
    parentEmitter.all.clear();
    childEmitter.all.clear();
  });

  useProvideOmniSearchContext({ parentEmitter, childEmitter });

  function getDatasetIdForDatasetIndex(datasetIndex) {
    return unref(datasets)[datasetIndex];
  }

  function getFocusedDatasetIndex() {
    return unref(datasets).findIndex((dataset) => unref(focusedItem)?.datasetId === dataset);
  }

  function updateFocusedItem(newFocusedItem: FocusedItem) {
    const oldDatasetId = unref(focusedItem)?.datasetId ?? null;
    if (oldDatasetId !== null && (newFocusedItem?.datasetId ?? null) !== oldDatasetId) {
      emitToChildren('focus:dataset:item', { targetDatasetId: oldDatasetId, itemIndex: null });
    }
    focusedItem.value = newFocusedItem;
    if (newFocusedItem) {
      emitToChildren('focus:dataset:item', {
        targetDatasetId: newFocusedItem.datasetId,
        itemIndex: newFocusedItem.itemIndex,
      });
    }
  }

  /**
   * Attempts to focus the first item, returns true if successful
   */
  function focusFirstItem(): boolean {
    const index = unref(datasets).findIndex((dataset) => unref(numMatchesMapRef)[dataset] > 0);
    if (index > -1) {
      updateFocusedItem({
        datasetId: getDatasetIdForDatasetIndex(index),
        itemIndex: 0,
      });
      return true;
    }

    updateFocusedItem(null);
    return false;
  }

  function focusPrevItem() {
    const numMatchesMap = unref(numMatchesMapRef);
    const focusedDatasetIndex = getFocusedDatasetIndex();
    if (unref(focusedItem)!.itemIndex > 0) {
      // move to previous item in dataset
      updateFocusedItem({
        ...unref(focusedItem)!,
        itemIndex: unref(focusedItem)!.itemIndex - 1,
      });
      return;
    }

    if (focusedDatasetIndex > 0) {
      let prevDatasetId;
      for (let i = focusedDatasetIndex - 1; i >= 0; i--) {
        const id = getDatasetIdForDatasetIndex(i);
        if (numMatchesMap[id] > 0) {
          prevDatasetId = id;
          break;
        }
      }

      if (prevDatasetId) {
        const prevDatasetMatchCount = unref(numMatchesMapRef)[prevDatasetId];
        // move to last item in previous dataset
        updateFocusedItem({
          itemIndex: prevDatasetMatchCount - 1,
          datasetId: prevDatasetId,
        });
      }
    }
    // Else: focus is at the first item and cannot move. If you need to implement focus wrapping, this is where you'd do it
  }

  function focusNextItem() {
    const numMatchesMap = unref(numMatchesMapRef);
    const numDatasets = unref(numDatasetsRef);
    const focusedDatasetIndex = getFocusedDatasetIndex();
    const focusedDatasetMatchCount = numMatchesMap[unref(focusedItem)!.datasetId];
    if (unref(focusedItem)!.itemIndex < focusedDatasetMatchCount - 1) {
      // move to next item in dataset
      updateFocusedItem({
        ...unref(focusedItem)!,
        itemIndex: unref(focusedItem)!.itemIndex + 1,
      });
      return;
    }

    if (focusedDatasetIndex < numDatasets - 1) {
      let nextDatasetId;
      for (let i = focusedDatasetIndex + 1; i < numDatasets; i++) {
        const id = getDatasetIdForDatasetIndex(i);
        if (numMatchesMap[id] > 0) {
          nextDatasetId = id;
          break;
        }
      }

      if (nextDatasetId) {
        // move to first item in next dataset
        updateFocusedItem({
          itemIndex: 0,
          datasetId: nextDatasetId,
        });
      }
    }
    // Else: focus is at the last item and cannot move. If you need to implement focus wrapping, this is where you'd do it
  }

  function clearFocus() {
    updateFocusedItem(null);
  }

  const doesActiveDatasetHaveASubmenu = computed(() => {
    const focus = unref(focusedItem);
    if (!focus) {
      return false;
    }

    return Boolean(unref(datasetSubmenuPresenceMap)[focus.datasetId]);
  });

  function moveFocusInDirection(
    direction: OMNI_DATASET_NAVIGATION_DIRECTION,
    event: KeyboardEvent,
  ) {
    if (!unref(focusedItem)) {
      if (!focusFirstItem()) {
        return;
      }
    }

    if (
      direction === OMNI_DATASET_NAVIGATION_DIRECTION.IN ||
      direction === OMNI_DATASET_NAVIGATION_DIRECTION.OUT
    ) {
      if (unref(doesActiveDatasetHaveASubmenu)) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }

    const datasetId = unref(focusedItem)?.datasetId;
    if (!datasetId || !Object.values(OMNI_DATASET_NAVIGATION_DIRECTION)?.includes(direction)) {
      return;
    }

    emitToChildren(`focus:dataset:move`, { targetDatasetId: datasetId, direction });
  }

  childEmitter.on('focus:item', (e) => {
    const [itemIndex] = e.payload;
    updateFocusedItem({ datasetId: e.id, itemIndex });
  });

  childEmitter.on('select', () => {
    // Wait one tick before setting to `searchTextAfterSelection` so the client has a change
    // to react to the selection and update `searchTextAfterSelection`. Code must use the updated
    // value because workflows reassign depends on it.
    nextTick(() => {
      const unwrappedSearchTextAfterSelection = unref(searchTextAfterSelection);
      if (unwrappedSearchTextAfterSelection !== false) {
        userEnteredText.value = unwrappedSearchTextAfterSelection ?? '';
      }
    });
  });

  childEmitter.on('match', (e) => {
    const [matches] = e.payload;
    const numMatches = matches?.length ?? 0;
    const prevMatchesCount = unref(numMatchesMapRef)[e.id];

    numMatchesMapRef.value = {
      ...unref(numMatchesMapRef),
      [e.id]: numMatches,
    };

    if (
      !unref(focusedItem) ||
      (e.id === unref(focusedItem)?.datasetId && prevMatchesCount !== numMatches)
    ) {
      // if the currently selected dataset has changed in length, focus the first item
      focusFirstItem();
    }
  });

  childEmitter.on('focus:next', focusNextItem);

  childEmitter.on('focus:previous', focusPrevItem);

  childEmitter.on('declare-submenu', (e) => {
    unref(datasetSubmenuPresenceMap)[e.id] = true;
  });

  const loadingDatasets: Ref<{ [datasetId: string]: boolean }> = ref({});
  childEmitter.on('loading:start', (e) => {
    loadingDatasets.value[e.id] = true;
  });
  childEmitter.on('loading:end', (e) => {
    loadingDatasets.value[e.id] = false;
  });
  const isLoading = computed(() => some(values(unref(loadingDatasets))));

  onMounted(() => {
    emitToChildren('input', unref(userEnteredText));
  });

  watch(userEnteredText, () => {
    emitToChildren('input', unref(userEnteredText));
  });

  function moveFocusNext(event: KeyboardEvent) {
    moveFocusInDirection(OMNI_DATASET_NAVIGATION_DIRECTION.NEXT, event);
  }

  function moveFocusPrevious(event: KeyboardEvent) {
    moveFocusInDirection(OMNI_DATASET_NAVIGATION_DIRECTION.PREVIOUS, event);
  }

  function moveFocusIn(event: KeyboardEvent) {
    moveFocusInDirection(OMNI_DATASET_NAVIGATION_DIRECTION.IN, event);
  }

  function moveFocusOut(event: KeyboardEvent) {
    moveFocusInDirection(OMNI_DATASET_NAVIGATION_DIRECTION.OUT, event);
  }

  return {
    userEnteredText,
    focusedItem,
    moveFocusNext,
    moveFocusPrevious,
    moveFocusIn,
    moveFocusOut,
    clearFocus,
    foundResults,
    emitToChildren,
    onParentMessage: parentEmitter.on,
    isLoading,
  };
}
